import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CFormInput, CDropdown, CDropdownToggle, CModal, CModalBody, CModalHeader, CModalTitle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CModalFooter } from '@coreui/react'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2'
import './Permisos.css'
import { TableVirtuoso } from 'react-virtuoso';
import InsertCambioFijo from './InsertCambioFijo';
import Select from 'react-select'

function CambiosFijos() {
  let [loading, setLoading] = useState(true)
  let [cambiosOG, setCambiosOG] = useState([])
  let [cambios, setCambios] = useState([])
  let [rutas, setRutas] = useState([])
  let [ruta, setRuta] = useState(null)
  let [botonRuta, setBotonRuta] = useState('Ruta')
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Status")
  let [tituloBoton2, setTituloBoton2] = useState("Dia")
  let [searchText, setSearchText] = useState("")
  let dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes"]
  let [visible1, setVisible1] = useState(false)
  let [filtros, setFiltros] = useState(false)
  let [alumnosSelect, setAlumnosSelect] = useState([])
  let [filtroAlumnosOn, setFiltroAlumnosOn] = useState(false)

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getCambios() {
    axios.get(`${process.env.REACT_APP_BackURL}/getallcambiofijo`)
      .then((response) => { setCambios(response.data.data.sort((a, b) => (a.dia > b.dia) ? 1 : -1)); setCambiosOG(response.data.data.sort((a, b) => (a.dia > b.dia) ? 1 : -1)); setLoading(false) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllRutas`)
      .then((response) => { setRutas(response.data.data.sort((a, b) => a.numero - b.numero)); })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAlumnos`)
      .then((response) => {
        let temp = []
        response.data.data.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
        setAlumnosSelect(temp);
      })
      .catch((err) => { console.log(err) })
  }

  // filter records by search text and option
  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = cambiosOG.filter((item) => {

      for (let key in filAcumulado) {
        if (item[key] === undefined || item[key] != filAcumulado[key])
          return false;
      }
      return true;
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setCambios(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setCambios(filteredData);
    }
  };

  function cambiarStatus(id, status, cambio, cancelar) {

    axios.patch(`${process.env.REACT_APP_BackURL}/updateCambioFijo?id=${id}`, { status: status, idRutaDestino: ruta }) //cambia el status del permiso
      .then((res) => {

        let cambiosTemp = [...cambios]
        let cambiosOGTemp = [...cambiosOG]

        let index = cambiosTemp.findIndex((cambioTemp) => cambioTemp._id == id)

        cambiosTemp[index].status = res.data.data.status
        cambiosOGTemp[index].status = res.data.data.status

        if (res.data.data.status == "Rechazado por escuela") {
          cambiosTemp[index].idRutaDestino = null
          cambiosOGTemp[index].idRutaDestino = null
          setBotonRuta('Ruta')
        }

        if (res.data.data.status == "Aceptado por escuela") {
          cambiosTemp[index].idRutaDestino = res.data.data.idRutaDestino
          cambiosOGTemp[index].idRutaDestino = res.data.data.idRutaDestino
        }

        setCambios(cambiosTemp)
        setCambiosOG(cambiosOGTemp)

      })
      .catch((err) => { console.log(err) })

    if (!cancelar) {

      if (cambio.tipo == "direccionTemporal") {

        if (cambio.deIda) {

          let alumno = cambio.alumnoInvitadoID

          alumno.direccionesFijasIda[cambio.dia] = { dia: dias[cambio.dia], direccion: cambio.direccionNombre }

          axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasIda: alumno.direccionesFijasIda })
            .then(() => { })
            .catch((err) => { console.log(err) })

        }

        if (cambio.deRegreso) {

          let alumno = cambio.alumnoInvitadoID

          alumno.direccionesFijasRegreso[cambio.dia] = { dia: dias[cambio.dia], direccion: cambio.direccionNombre }

          axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
            .then(() => { })
            .catch((err) => { console.log(err) })

        }
      }

      if (cambio.tipo == "papaRecoge") {

        let alumno = cambio.alumnoInvitadoID

        alumno.direccionesFijasRegreso[cambio.dia] = { dia: dias[cambio.dia], direccion: 'papaRecoge' }

        axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
          .then(() => { })
          .catch((err) => { console.log(err) })

      }

    }


    if (cancelar) {

      if (cambio.tipo == "direccionTemporal") {

        if (cambio.deIda) {

          let alumno = cambio.alumnoInvitadoID

          alumno.direccionesFijasIda[cambio.dia] = { dia: dias[cambio.dia], direccion: 'Principal' }

          axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasIda: alumno.direccionesFijasIda })
            .then(() => { })
            .catch((err) => { console.log(err) })

        }

        if (cambio.deRegreso) {
          let alumno = cambio.alumnoInvitadoID

          alumno.direccionesFijasRegreso[cambio.dia] = { dia: dias[cambio.dia], direccion: 'Principal' }

          axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
            .then(() => { })
            .catch((err) => { console.log(err) })

        }
      }

      if (cambio.tipo == "papaRecoge") {

        let alumno = cambio.alumnoInvitadoID

        alumno.direccionesFijasRegreso[cambio.dia] = { dia: dias[cambio.dia], direccion: 'Principal' }

        axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
          .then(() => { })
          .catch((err) => { console.log(err) })
      }

    }

  }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function existe(key, value) {
    let siHay = false

    cambios.forEach((alumno) => {
      if (alumno[key] == value) { siHay = true }
    })

    return siHay
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function cruzaRutas() {
    axios.post(`${process.env.REACT_APP_BackURL}/revisaPermisosManual`, { ida: false, regreso: true })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Cambios aplicados',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((err) => { console.log(err) })
  }

  function filtrarPorAlumno(alumno) {
    let temp = cambiosOG.filter((cambio) => cambio.alumnoInvitadoID._id == alumno)
    setCambios(temp)
  }

  function onSuccess(response) {
    setVisible1(false)
    setCambios([...cambios, response])
    setCambiosOG([...cambiosOG, response])
  }

  useLayoutEffect(() => {
    getCambios()
  }, [])

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <>

      <CModal visible={visible1} size='lg'>

        <CModalHeader closeButton={false}>
          <CModalTitle>Crear nuevo cambio fijo</CModalTitle>
        </CModalHeader>

        <CModalBody style={{ backgroundColor: "rgb(235,237,239" }}>
          <InsertCambioFijo alumnos={alumnosSelect} rutas={rutas} onSuccess={onSuccess} />
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible1(false)}>Cancelar</CButton>
        </CModalFooter>

      </CModal>


      <div className='centro'>

        <div className='botones'>

          <CButton onClick={() => cruzaRutas()} color="info">Aplicar cambios</CButton>

          <CButton onClick={() => setVisible1(true)} color="success">Nuevo cambio fijo</CButton>
        </div>

        <br />
        {/* 
        <center>

          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { setSearchText(e.target.value) })} />


          <br />



        </center> */}

        <div className='botones'>

          {
            filtros ? null :
              !loading ?
                <Select className='buscaAlumnos' placeholder="Buscar alumnos" options={alumnosSelect} onChange={(e) => { filtrarPorAlumno(e.value.alumno.id); setFiltroAlumnosOn(true) }} />

                : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
          }

          {
            filtroAlumnosOn ? null :
              <>
                <CDropdown className='p-1'>

                  <CDropdownToggle color={filtroActivo(filtroAcumulado, "dia") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

                  <CDropdownMenu className='dropdown-menu'>

                    {
                      !filtroActivo(filtroAcumulado, "dia") ? null :
                        <CDropdownItem onClick={() => { eliminarFiltroAcumulado("dia"); setTituloBoton2("Dia") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                    }

                    {
                      [...new Set(cambiosOG.map((item) => item.dia))].map((dia, i) => (

                        <CDropdownItem key={i} onClick={() => { filtrarAcumulado("dia", dia); setTituloBoton2(`${dias[dia]}`); setFiltros(true) }} className={!existe("dia", dia) ? "noHay" : "siHay"}>{dias[dia]}</CDropdownItem>
                      ))
                    }

                  </CDropdownMenu>

                </CDropdown>

                <CDropdown className='p-1'>

                  <CDropdownToggle color={filtroActivo(filtroAcumulado, "status") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

                  <CDropdownMenu className='dropdown-menu'>

                    {
                      !filtroActivo(filtroAcumulado, "status") ? null :
                        <CDropdownItem onClick={() => { eliminarFiltroAcumulado("status"); setTituloBoton1("Status") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                    }
                    {
                      [...new Set(cambiosOG.map((item) => item.status))].map((status, i) => (

                        <CDropdownItem key={i} onClick={() => { filtrarAcumulado("status", status); setTituloBoton1(`Status ${status}`); setFiltros(true) }} className={!existe("status", status) ? "noHay" : "siHay"}>{status}</CDropdownItem>
                      ))
                    }

                  </CDropdownMenu>

                </CDropdown>
              </>
          }

          <CButton color='danger' onClick={() => { setFiltroAcumulado({}), setCambios(cambiosOG), setFiltroAlumnosOn(false), setFiltros(false) }}>Reset filtros</CButton>

          <h5>{cambios.length} cambios registrados</h5>

        </div>
        <br />

        {
          !loading ?
            cambios.length == 0 ?
              <center><h1>No hay cambios registrados</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={cambios}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Dia</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Tipo</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Status</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Alumno / Codigo</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>A casa de:</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisos'>Direccion Destino</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Escoger Ruta</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Autorizar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>{dias[item.dia]}</CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>{item.tipo == "direccionTemporal" ? "Direccion temporal" : "papaRecoge"}</CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>{item.status}</CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>
                          <React.Fragment>
                            {item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                            <br />
                            {item.alumnoInvitadoID.student_code}
                          </React.Fragment>
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>{item.tipo == "direccionTemporal" ? item.direccionNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : "No aplica"}</CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>{item.tipo == "direccionTemporal" ? item.direccionDestino : "No aplica"}</CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cells'>
                          {
                            item.tipo == "direccionTemporal" ?
                              item.status == "Aceptado por escuela" ?
                                `${!item.idRutaDestino.numero ? null : item.idRutaDestino.numero} ${!item.idRutaDestino.nombre ? null : item.idRutaDestino.nombre} ${!item.idRutaDestino.direccionRuta ? null : item.idRutaDestino.direccionRuta}`
                                :
                                <CDropdown className='p-1' direction='dropstart'>
                                  <CDropdownToggle color={"success"}>{botonRuta}</CDropdownToggle>
                                  <CDropdownMenu className='dropdown-menu'>
                                    {
                                      rutas.map((ruta, i) => (
                                        <CDropdownItem key={i} onClick={() => { setRuta(ruta._id); setBotonRuta(`Ruta ${ruta.numero}`) }}>{ruta.numero} {ruta.nombre} {ruta.direccionRuta}</CDropdownItem>
                                      ))
                                    }
                                  </CDropdownMenu>
                                </CDropdown>
                              : "No Aplica"
                          }
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "Rechazado por escuela" ? "danger" : null} className='cellsPermisos'>
                          {
                            item.tipo == "direccionTemporal" ?

                              ruta ?
                                <>
                                  <CButton disabled={item.status == "Pendiente" || item.status == "Rechazado por escuela" ? false : true} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item, false) }}><i className="fa-solid fa-check"></i></CButton>
                                </>
                                :
                                <>
                                  <CButton disabled={true} color="success"><i className="fa-solid fa-check"></i></CButton>
                                </>
                              :
                              <>
                                <CButton disabled={item.status == "Rechazado por escuela" ? false : true} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item, false) }}><i className="fa-solid fa-check"></i></CButton>
                              </>


                          }
                          <CButton disabled={item.status == "Rechazado por escuela" ? true : false} style={{ marginLeft: "5px" }} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item, true) }}><i className="fa-solid fa-xmark"></i></CButton>
                        </CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div >
    </>
  )
}

export default CambiosFijos
