import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CModal, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModalHeader, CModalTitle, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilChatBubble, cilX, cilWarning } from '@coreui/icons';
import { TableVirtuoso } from 'react-virtuoso';
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client'
import exportFromJSON from "export-from-json";
import './HistorialRutas.css'
import Mapa from './Mapa'
import Select from 'react-select'

const socket = io(process.env.REACT_APP_BackURLSocket)

function HistorialRutas() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [rutasOG, setRutasOG] = useState([])
  let [rutas, setRutas] = useState([])
  let [alumnos, setAlumnos] = useState([])
  let [alumnosSelect, setAlumnosSelect] = useState([])
  let [rutaDireccionDisplay, setRutaDireccionDisplay] = useState()
  let [diaDireccionDisplay, setDiaDireccionDisplay] = useState()
  let [alumnosDisplay, setAlumnosDisplay] = useState([])
  let [alumnosDisplayAbordados, setAlumnosDisplayAbordados] = useState([])
  let [alumnosDisplayEntregados, setAlumnosDisplayEntregados] = useState([])
  let [selectedDate, setSelectedDate] = useState(new Date());
  let [ubicaciones, setUbicaciones] = useState([])
  let [alumnosParadas, setAlumnosParadas] = useState([])
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Status")
  let [tituloBoton2, setTituloBoton2] = useState("Direccion")
  let [direccionDisplay, setDireccionDisplay] = useState("")
  let [mensaje, setMensaje] = useState("")
  let [idsNotificados, setIdsNotificados] = useState([])
  let dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
  let excludeColumns = ["_id", "is_active", "createdAt", "password", "updatedAt",];
  let [tituloRuta, setTituloRuta] = useState("")
  let [rutaSeleccionada, setRutaSeleccionada] = useState()
  let [ubicacionRuta, setUbicacionRuta] = useState()
  let [live, setLive] = useState()
  let [enRuta, setEnRuta] = useState(false)
  let [buscador1, setBuscador1] = useState(true)
  let [buscador2, setBuscador2] = useState(true)
  let [buscador3, setBuscador3] = useState(true)

  function getRutas() {

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAlumnos`)
      .then((response) => {
        let temp = []
        response.data.data.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
        setAlumnosSelect(temp);
        setAlumnos(response.data.data)

        axios.get(`${process.env.REACT_APP_BackURL}/getHistorialRutasByDate?dia=${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`)
          .then((response) => {
            setRutas(response.data.data.sort((a, b) => a.rutaID.numero - b.rutaID.numero));
            setRutasOG(response.data.data.sort((a, b) => a.rutaID.numero - b.rutaID.numero));
            setLoading(false)
          })
          .catch((err) => { console.log(err) })

      })
      .catch((err) => { console.log(err) })

  }

  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = rutasOG.filter((item) => {

      for (let key in filAcumulado) {
        if (item.rutaID[key] != filAcumulado[key]) {
          if (item[key] === undefined || item[key] != filAcumulado[key])
            return false;
        }
      }
      return true;
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setRutas(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          console.log(item),
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });

      setRutas(filteredData);
    }
  };

  function buscarAlumno(value) {
    let resultadoFiltrado = rutasOG.filter((ruta) => {
      return ruta.direccionesTemporales.some((alumno) => alumno.id == value)
    })

    setRutas(resultadoFiltrado)
  }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function exportarExcel() {
    let datos = []
    // let cantidad = alumnos.map((v) => v.cantidad)
    // let createdAt = alumnos.map((v) => v.createdAt)
    // let donador = alumnos.map((v) => v.donadorID.nombreCompleto)
    // let vendedor = alumnos.map((v) => v.abrejID.nombre)
    // let stripeOpaypal = alumnos.map((v) => v.stripeOpaypal)

    // for (var i = 0; i < cantidad.length; i++) {
    //   datos.push({
    //     'Cantidad': `$${cantidad[i]}`,
    //     'Desde': new Date(createdAt[i]).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }),
    //     'Donador': donador[i],
    //     'Vendedor': vendedor[i],
    //     'Stripe / PayPal': stripeOpaypal[i],
    //   });
    // }

    // exportFromJSON({ data: datos, fileName: "Reporte_Padres", exportType: "csv" })
  }

  function alumnosInfo(alumnosRuta) {

    for (let i in alumnosRuta) {

      let alumnoInfo = alumnos.find((alumno) => alumno._id == alumnosRuta[i].id)

      if (alumnoInfo) {
        alumnosRuta[i].student_code = alumnoInfo.student_code
        alumnosRuta[i].nombre = alumnoInfo.nombre
        alumnosRuta[i].degree = alumnoInfo.degree
        alumnosRuta[i].padres = alumnoInfo.padres
        alumnosRuta[i].direccionesFijasIda = alumnoInfo.direccionesFijasIda
        alumnosRuta[i].direccionesFijasRegreso = alumnoInfo.direccionesFijasRegreso
      } else {

        alumnosRuta[i].student_code = 'Alumno baja'
        alumnosRuta[i].nombre = 'Alumno baja'
        alumnosRuta[i].degree = 'Alumno baja'
        alumnosRuta[i].padres = 'Alumno baja'
        alumnosRuta[i].direccionesFijasIda = 'Alumno baja'
        alumnosRuta[i].direccionesFijasRegreso = 'Alumno baja'

      }

    }

    setAlumnosDisplay(alumnosRuta)

  }

  function displayRuta(ruta) {

    setRutaSeleccionada(ruta)

    axios.get(`${process.env.REACT_APP_BackURL}/findOneHistorialRutas?id=${ruta}`)
      .then((response) => {

        setEnRuta(response.data.status != "enRuta" ? false : true)
        setTituloRuta(`Ruta ${response.data.rutaID.numero} ${response.data.rutaID.nombre}`)
        setAlumnosDisplayAbordados(response.data.alumnosAbordados)
        setAlumnosDisplayEntregados(response.data.alumnosEntregados)
        setDireccionDisplay(response.data.rutaID.direccionRuta)

        let stops = []

        let ubications = []

        response.data.ubicaciones.filter(objeto => objeto !== null && typeof objeto === 'object').forEach((ubicacion, index) => {
          ubications.push({ coordinates: [ubicacion.coords.longitude, ubicacion.coords.latitude], name: index == 0 ? 'Inicio de ruta' : index == response.data.ubicaciones.length - 1 ? 'Final de ruta' : '', type: index == 0 ? 'start' : index == response.data.ubicaciones.length - 1 ? 'end' : '' })

          if (index !== 0 && index !== response.data.ubicaciones.length - 1) {
            delete ubications[index].type;
            delete ubications[index].name;
          }
        })

        setUbicaciones(ubications)

        if (response.data.rutaID.direccionRuta == "Ida") {
          response.data.alumnosAbordados.forEach((alumno) => {
            stops.push({ coordinates: [alumno.ubicacion.longitude, alumno.ubicacion.latitude], name: alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) })
          })

          setAlumnosParadas(stops)
        }

        if (response.data.rutaID.direccionRuta == "Regreso") {
          response.data.alumnosEntregados.forEach((alumnoEnt) => {

            let alumnoInfo = response.data.alumnosAbordados.find((alumno) => alumno.id == alumnoEnt.alumnoID)

            stops.push({ coordinates: [alumnoEnt.ubicacion.longitude, alumnoEnt.ubicacion.latitude], name: alumnoInfo.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) })

          })

          setAlumnosParadas(stops)
        }

      })
      .catch((err) => { console.log(err) })

  }

  function getRutasByDate(date) {

    setLoading(true)

    axios.get(`${process.env.REACT_APP_BackURL}/getHistorialRutasByDate?dia=${date}`)
      .then((response) => {
        setRutas(response.data.data.sort((a, b) => a.rutaID.numero - b.rutaID.numero));
        setRutasOG(response.data.data.sort((a, b) => a.rutaID.numero - b.rutaID.numero));
        setLoading(false);
        setFiltroAcumulado({});
        setTituloBoton1("Status");
        setTituloBoton2("Direccion");
      })
      .catch((err) => { console.log(err) })

  }

  function notificarGeneral() {

    let ids = []

    rutas.forEach((ruta) => {
      if (ruta.alumnosAbordados.length > 0) {
        ruta.alumnosAbordados.forEach((alumno) => ids.push(alumno.id))
      }
    })

    axios.post(`${process.env.REACT_APP_BackURL}/insertAnuncio`, { fecha: new Date().toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }), anuncio: mensaje, idsNotificados: ids })
      .then(() => {
        Swal.fire({ icon: 'success', title: 'Notificacion enviada', showConfirmButton: false, timer: 1500 })
        setVisible1(false)
      })
      .catch((err) => { console.log(err) })

  }

  function notificarRuta() {
    axios.post(`${process.env.REACT_APP_BackURL}/insertAnuncio`, { fecha: new Date().toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }), anuncio: mensaje, idsNotificados: idsNotificados })
      .then(() => {
        Swal.fire({ icon: 'success', title: 'Notificacion enviada', showConfirmButton: false, timer: 1500 })
        setVisible1(false)
      })
      .catch((err) => { console.log(err) })

  }

  useLayoutEffect(() => {
    getRutas()
    socket.on('routeLocation', (data) => { setUbicacionRuta(data) })
  }, [])


  useEffect(() => {

    if (ubicacionRuta) {

      if (rutaSeleccionada != null) {

        if (ubicacionRuta.rutaID == rutaSeleccionada) {

          setLive(ubicacionRuta)

        }
      }
    }
  }, [ubicacionRuta])


  useEffect(() => {
    filterData("", filtroAcumulado)
  }, [filtroAcumulado])

  return (
    <>
      <div>

        <CModal visible={visible1} >

          <CModalHeader closeButton={false}>
            <CModalTitle>Enviar notificacion a App</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <center>
              {
                idsNotificados.length > 0 ?
                  <h6>Se notificará a todos los alumnos abordados en la ruta</h6>
                  :
                  <h6>Se notificará a los papas de los alumnos abordados en las rutas que se muestran en pantalla pantalla</h6>
              }
              <br />
              <h6>Escribe el mensaje</h6>
              <CFormInput placeholder='Mensaje...' style={{ "maxWidth": "400px" }} className='col' type="text" onChange={(e) => { setMensaje(e.target.value) }} /> <br />
            </center>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cancelar</CButton>
            <CButton color="info" onClick={() => { idsNotificados.length > 0 ? notificarRuta() : notificarGeneral() }}>Enviar</CButton>
          </CModalFooter>

        </CModal>

        {/* <CModal visible={visible1} onClose={() => setVisible1(false)} size='xl'>

          <CModalHeader onClose={() => setVisible1(false)}>
            <CModalTitle>Alumnos en ruta</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>

              <CTableHead>
                <CTableRow color='dark'>
                  <CTableHeaderCell>Horario</CTableHeaderCell>
                  <CTableHeaderCell>Codigo alumno</CTableHeaderCell>
                  <CTableHeaderCell>Alumno</CTableHeaderCell>
                  <CTableHeaderCell>Abordado</CTableHeaderCell>
                  <CTableHeaderCell>Entregado</CTableHeaderCell>
                  <CTableHeaderCell>Hora</CTableHeaderCell>
                  <CTableHeaderCell>Grado</CTableHeaderCell>
                  <CTableHeaderCell>Direccion</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  alumnosDisplay.map((item, i) => {
                    return (
                      <CTableRow key={i} color="info">
                        <CTableDataCell>{item.horario}</CTableDataCell>
                        <CTableDataCell>{item.student_code}</CTableDataCell>
                        <CTableDataCell>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell>{alumnosDisplayAbordados.find((alumno) => alumno.id == item.id) ? <CIcon icon={cilCheckAlt} size="xl" /> : <CIcon icon={cilX} size="xl" />}</CTableDataCell>
                        <CTableDataCell>{alumnosDisplayEntregados.find((alumno) => alumno.id == item.id) ? <CIcon icon={cilCheckAlt} size="xl" /> : <CIcon icon={cilX} size="xl" />}</CTableDataCell>
                        <CTableDataCell>{alumnosDisplayEntregados.find((alumno) => alumno.id == item.id) ? new Date(alumnosDisplayEntregados.find((alumno) => alumno.id == item.id).ubicacionEntrega.timestamp).getHours() + ":" + new Date(alumnosDisplayEntregados.find((alumno) => alumno.id == item.id).ubicacionEntrega.timestamp).getMinutes().toString().padStart(2, '0') : null}</CTableDataCell>
                        <CTableDataCell>{item.degree}</CTableDataCell>
                        <CTableDataCell>{item.student_code == 'Alumno baja' ? 'Alumno baja' : rutaDireccionDisplay == "Ida" ? item.direccionesFijasIda[diaDireccionDisplay].direccion == "Principal" ? item.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccionesFijasIda[diaDireccionDisplay].direccion).map((papa) => papa.contact_address) : item.direccionesFijasRegreso[diaDireccionDisplay].direccion == "Principal" ? item.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccionesFijasRegreso[diaDireccionDisplay].direccion).map((papa) => papa.contact_address)}</CTableDataCell>
                      </CTableRow>
                    )
                  })
                }
              </CTableBody>

            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal> */}

        <center>
          <h1>Rutas del dia</h1>
        </center>

        <br />

        <div className='botones'>

          <h6>Fecha seleccionada</h6>

          <DatePicker dateFormat="dd/MM/yyyy" selected={selectedDate} onChange={(date) => { setSelectedDate(date); getRutasByDate(`${new Date(date).getFullYear()}/${new Date(date).getMonth() + 1}/${new Date(date).getDate()}`) }} />

          {
            !buscador3 ? null :
              <CDropdown className='p-1'>

                <CDropdownToggle color={filtroActivo(filtroAcumulado, "status") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>
                <CDropdownMenu className='dropdown-menu'>

                  {
                    !filtroActivo(filtroAcumulado, "status") ? null :
                      <CDropdownItem onClick={() => { eliminarFiltroAcumulado("status"); setTituloBoton1("Status"); setBuscador1(true) }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                  }
                  {
                    [...new Set(rutasOG.map((item) => item.status))].map((status, i) => (

                      <CDropdownItem key={i} onClick={() => { filtrarAcumulado("status", status); setTituloBoton1(status); setBuscador1(false) }} className={"siHay"}>{status}</CDropdownItem>
                    ))
                  }

                </CDropdownMenu>

              </CDropdown>
          }

          {
            !buscador3 ? null :
              <CDropdown className='p-1'>

                <CDropdownToggle color={filtroActivo(filtroAcumulado, "direccionRuta") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

                <CDropdownMenu className='dropdown-menu'>

                  {
                    !filtroActivo(filtroAcumulado, "direccionRuta") ? null :
                      <CDropdownItem onClick={() => { eliminarFiltroAcumulado("direccionRuta"); setTituloBoton2("Direccion"), setBuscador2(true) }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                  }
                  {
                    [...new Set(rutasOG.map((item) => item.rutaID.direccionRuta))].map((direccionRuta, i) => (

                      <CDropdownItem key={i} onClick={() => { filtrarAcumulado("direccionRuta", direccionRuta); setTituloBoton2(direccionRuta); setBuscador2(false) }} className={"siHay"}>{direccionRuta}</CDropdownItem>
                    ))
                  }

                </CDropdownMenu>

              </CDropdown>
          }

          {/* <CButton color="info" onClick={() => { setVisible1(true) }}>Notificar App</CButton> */}

          {
            !loading ?
              !buscador1 || !buscador2 ? null :
                <div className='botones'>
                  <Select className='index' placeholder="Buscar alumnos" options={alumnosSelect} onChange={(e) => { buscarAlumno(e.value.alumno.id); setBuscador3(false) }} />

                  <CButton onClick={() => { setRutas(rutasOG); setBuscador3(true) }} color="danger">Reset filtro</CButton>
                </div>

              : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
          }


        </div>

        <br />

        {/* <br /> */}

        {/* {tituloRuta == "" ? <br /> : <center><h3>{tituloRuta}</h3></center>} */}

        <div className='fila'>

          <div className='columna'>

            {
              !loading ?
                rutas.length == 0 ?
                  <>
                    <center>
                      <h4 style={{ height: "40vh", width: '40vw', zIndex: '0' }}>Sin resultados</h4>
                    </center>
                  </> :
                  <>
                    <TableVirtuoso
                      style={{ height: "70vh", width: '40vw', zIndex: '0' }}
                      data={rutas}
                      components={{
                        Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                        TableHead: CTableHead,
                        TableRow: CTableRow,
                        TableBody: CTableBody
                      }}

                      fixedHeaderContent={() => (
                        <CTableRow color='info'>
                          {/* <CTableHeaderCell className='cells'>Día</CTableHeaderCell> */}
                          {/* <CTableHeaderCell className='cells'>Fecha</CTableHeaderCell> */}
                          <CTableHeaderCell className='cellsChica'>Numero</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Status</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Prefecta</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Direccion</CTableHeaderCell>
                          {/* <CTableHeaderCell className='cells'>Notificar</CTableHeaderCell> */}
                          {/* <CTableHeaderCell className='cells'>Alumnos</CTableHeaderCell> */}
                        </CTableRow>
                      )}

                      itemContent={(index, item) => {
                        return (
                          <>
                            {/* <CTableDataCell onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{dias[new Date(item.fecha).getDay()]}</CTableDataCell> */}
                            {/* <CTableDataCell onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{`${item.fecha.split('/')[2]}/${item.fecha.split('/')[1]}/${item.fecha.split('/')[0]}`}</CTableDataCell> */}
                            <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cellsChica'>{!item.rutaID ? "" : item.rutaID.numero}</CTableDataCell>
                            <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{!item.rutaID ? "" : item.rutaID.nombre}</CTableDataCell>
                            <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{item.status}</CTableDataCell>
                            <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{!item.prefectaID ? "" : item.prefectaID.collaborator_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                            <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'>{!item.rutaID ? "" : item.rutaID.direccionRuta}</CTableDataCell>
                            {/* <CTableDataCell color={rutaSeleccionada == item._id ? "success" : 'dark'} onClick={() => { displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }} className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { item.alumnosAbordados.map((alumno) => alumno.id).length > 0 ? setVisible1(true) : Swal.fire({ icon: 'warning', title: 'No hay alumnos abordados en esta ruta', text: "No hay a quien notificar" }); setIdsNotificados(item.alumnosAbordados.map((alumno) => alumno._id)) }}><CIcon icon={cilChatBubble} size="xl" /></CButton></CTableDataCell> */}
                            {/* <CTableDataCell onClick={() => {displayRuta(item._id); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) } } className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); alumnosInfo(item.direccionesTemporales); setRutaDireccionDisplay(!item.rutaID ? "" : item.rutaID.direccionRuta); setDiaDireccionDisplay(new Date(item.fecha).getDay()) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell> */}
                          </>
                        )
                      }}
                    />
                  </>
                : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
            }

          </div>

          <div className='columna'>

            <Mapa ubicaciones={ubicaciones} alumnosParadas={alumnosParadas} live={live} enRuta={enRuta} tituloRuta={tituloRuta} />

            {
              alumnosDisplay.length == 0 ?
                <>
                  <center>
                    <h4 style={{ height: "40vh", width: '40vw', zIndex: '0' }}>Selecciona una ruta para ver los alumnos</h4>
                  </center>
                </> :
                !loading ?
                  <>
                    <TableVirtuoso
                      style={{ height: "40vh", width: '40vw', zIndex: '0' }}
                      data={alumnosDisplay}
                      components={{
                        Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(218,234,253)" }} color="info" />,
                        TableHead: CTableHead,
                        TableRow: CTableRow,
                        TableBody: CTableBody
                      }}

                      fixedHeaderContent={() => (
                        <CTableRow color='primary'>
                          <CTableHeaderCell className='cells'>Horario</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Alumno</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Codigo</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Abordado</CTableHeaderCell>
                          {
                            direccionDisplay == "Regreso" ?
                              <CTableHeaderCell className='cells'>Entregado</CTableHeaderCell>
                              :
                              null
                          }
                          <CTableHeaderCell className='cells'>Hora</CTableHeaderCell>
                          <CTableHeaderCell className='cellsGrande'>Grado</CTableHeaderCell>
                          <CTableHeaderCell className='cells'>Direccion</CTableHeaderCell>
                        </CTableRow>
                      )}

                      itemContent={(index, item) => {
                        return (
                          <>
                            <CTableDataCell className='cells'>{item.horario}</CTableDataCell>
                            <CTableDataCell className='cellsGrande'>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.student_code}</CTableDataCell>
                            {
                              direccionDisplay == "Regreso" ?
                                <CTableDataCell className='cells'>{alumnosDisplayAbordados.find((alumno) => alumno.id == item.id) ? <div className='palomita'><CIcon icon={cilCheckAlt} size="xl" /></div> : <div className='tache'><CIcon icon={cilX} size="xl" /></div>}</CTableDataCell>
                                :
                                <CTableDataCell className='cells'>{alumnosDisplayAbordados.find((alumno) => alumno.alumnoID == item.id) ? <div className='palomita'><CIcon icon={cilCheckAlt} size="xl" /></div> : <div className='tache'><CIcon icon={cilX} size="xl" /></div>}</CTableDataCell>
                            }
                            {
                              direccionDisplay == "Regreso" ?
                                <CTableDataCell className='cells'>{alumnosDisplayEntregados.find((alumno) => alumno.alumnoID == item.id) ? <div className='palomita'>{alumnosDisplayEntregados.filter((alumno) => alumno.enEscuela).map((alumno) => alumno.alumnoID).includes(item.id) ? <CIcon style={{ color: "#f5cb42" }} icon={cilWarning} size="xl" /> : <CIcon style={{ color: "green" }} icon={cilCheckAlt} size="xl" />} </div> : <div className='tache'><CIcon icon={cilX} size="xl" /></div>}</CTableDataCell>
                                :
                                null
                            }
                            {
                              direccionDisplay == "Regreso" ?
                                <CTableDataCell className='cells'>{alumnosDisplayEntregados.find((alumno) => alumno.alumnoID == item.id) ? new Date(alumnosDisplayEntregados.find((alumno) => alumno.alumnoID == item.id).hora).getHours() + ":" + new Date(alumnosDisplayEntregados.find((alumno) => alumno.alumnoID == item.id).hora).getMinutes().toString().padStart(2, '0') : null}</CTableDataCell>
                                :
                                <CTableDataCell className='cells'>{alumnosDisplayAbordados.find((alumno) => alumno.alumnoID == item.id) ? new Date(alumnosDisplayAbordados.find((alumno) => alumno.alumnoID == item.id).hora).getHours() + ":" + new Date(alumnosDisplayAbordados.find((alumno) => alumno.alumnoID == item.id).hora).getMinutes().toString().padStart(2, '0') : null}</CTableDataCell>
                            }
                            <CTableDataCell className='cellsGrande'>{item.degree}</CTableDataCell>
                            <CTableDataCell className='cellsMasGrande'>{item.student_code == 'Alumno baja' ? 'Alumno baja' : item.direccionDestino ? item.direccionDestino : rutaDireccionDisplay == "Ida" ? item.direccionesFijasIda[diaDireccionDisplay].direccion == "Principal" ? item.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccionesFijasIda[diaDireccionDisplay].direccion).map((papa) => papa.contact_address) : item.direccionesFijasRegreso[diaDireccionDisplay].direccion == "Principal" ? item.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccionesFijasRegreso[diaDireccionDisplay].direccion).map((papa) => papa.contact_address)}</CTableDataCell>
                          </>
                        )
                      }}
                    />
                  </>
                  : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
            }

          </div>

        </div>

        <br />

      </div>
    </>
  )
}

export default HistorialRutas
